import {TOGGL_FILL, IS_SINGLE, RESET_P5, FREQUENCY} from './p5-actions';

const p5Reducer = (state, action) => {
    switch (action.type) {
        case TOGGL_FILL:
            return {
                ...state,
                toggleFill: !state.toggleFill
            };
        case IS_SINGLE:
            return {
                ...state,
                isSingle: !state.isSingle
            };
        case RESET_P5:
            return {
                ...state,
                resetP5: action.payload
            };
        case FREQUENCY:
            return {
                ...state,
                frequencyP5: action.payload
            };
        default:
            return state;
    }
};

export default p5Reducer
