
function reduceDenominator(numerator, denominator) {
    function rec(a, b) {
        return b ? rec(b, a % b) : a;
    }
    return denominator / rec(numerator, denominator);
}

function mapExp(p5, input, in_min, in_max) {
    let zmX = p5.map(input, in_min, in_max, 0, 2);
    let zxX = p5.exp(zmX);
    let zaX = p5.map(zxX, 1, p5.exp(2), in_min, in_max);
    return zaX;
}

function getRandomInt(min, max) {
    let inc = max + 1 - min;
    let resss = Math.floor(Math.random() * Math.floor(inc));

    return min + resss;
}

export {
    reduceDenominator,
    mapExp,
    getRandomInt
}
