import React, {useReducer} from 'react';
import p5Context from "./p5-context";
import p5Reducer from "./p5-reducer";
import {TOGGL_FILL, IS_SINGLE, RESET_P5, FREQUENCY} from './p5-actions';


const P5State = (props) => {
    const initialState = {
        toggleFill: false,
        isSingle: true,
        resetP5: false,
        frequencyP5: 0
    }

    const [state, dispatch] = useReducer(p5Reducer, initialState)

    const setToggleFill = () => {
        dispatch({
            type: TOGGL_FILL
        })
    }

    const setIsSingle = () => {
        dispatch({
            type: IS_SINGLE
        })
    }

    const setResetP5 = (resetp5) => {
        dispatch({
            type: RESET_P5,
            payload: resetp5
        })
    }

    const setFrequencyP5 = (freq) => {
        dispatch({
            type: FREQUENCY,
            payload: freq
        })
    }




    return (
        <p5Context.Provider value={{
            toggleFill: state.toggleFill,
            isSingle: state.isSingle,
            resetP5: state.resetP5,
            frequencyP5: state.frequencyP5,
            setToggleFill,
            setIsSingle,
            setResetP5,
            setFrequencyP5
        }}>
            {props.children}
        </p5Context.Provider>
    );
};

export default P5State;