import React, {useContext} from 'react';
import p5Context from "../context/p5-context";


const Frequency = () => {
    const {frequencyP5} = useContext(p5Context)
    return (
        <>
            <span className="font-normal text-white text-xs md:text-sm xl:text-xl">
                {frequencyP5} Hz
            </span>
        </>
    );
};

export default Frequency;