import {getRandomInt} from "./moreFunct";

// import myShape from "./myShape";

class myShapeArr {
    constructor(p5, in_pox, in_poy) {
        // this.pox = random(0,width);
        // this.poy = random(0,height);

        this.randFill = Math.random() > 0.5;

        this.pox = in_pox;
        this.poy = in_poy;
        // this.xlSize = random(0.25,1.25);
        // this.ylSize = random(0.25,1.25);
        // this.xlSize = 1.2;
        // this.ylSize = 1.2;
        // this.xlSize = 0.01;
        // this.ylSize = 0.01;
        this.xlSize = p5.random(0.01, 0.05);
        this.ylSize = p5.random(0.01, 0.05);
        // console.log("x " + this.xlSize);
        // console.log("y " + this.ylSize);
        this.randRot = p5.random(360);
        // this.randRot = random(0,360);

        this.shapeSize = p5.height / 3;
        this.aCos = 1;
        this.aSin = 1;

        this.selAl = p5.random(0, 100);
        this.d = p5.floor(p5.random(1, 24));
        this.n = p5.floor(p5.random(1, 24));
        this.sizeA = p5.width / 1000;
        // this.cR = floor(random(0,255));
        // this.cG = floor(random(0,255));
        // this.cB = floor(random(0,255));
        // this.sC = floor(random(0,6.9));
        this.sC = getRandomInt(0, 6);
        this.selFill = true;
        this.blue_bg = [
            "#e2f2fO",
            "#ccdde6",
            "#80a9bc",
            "#569bab",
            "#0087ab",
            "#005a88",
            "#004562"
        ];
        this.white_bg = [
            "#e5a82e",
            "#978445",
            "#73aa6a",
            "#c53f38",
            "#7f528f",
            "#0087ab",
            "#004562"
        ];

        ////
        // console.log(this.sC);
    }

    update(p5, in_Freq) {
        // this.shapeSize = height / 2;
        // this.shapeSize = constrain(this.shapeSize,1,width*0.2);
        // this.shapeSize = constrain(this.shapeSize,0,width*0.25);
        // aCos = map(ud_value, 20, 20000, 0, 1000);

        if (this.selAl < 50) {
            this.aCos = p5.map(in_Freq, 40, 15000, 1, 32);
            this.aSin = p5.map(in_Freq, 1200, 15000, 1, 24.5);
        } else {
            this.aCos = p5.map(in_Freq, 1200, 15000, 1, 32);
            this.aSin = p5.map(in_Freq, 40, 15000, 1, 24.5);
        }

        this.aCos = p5.constrain(this.aCos, 1, 100);
        this.aSin = p5.constrain(this.aSin, 1, 100);
    }

    show(p5, in_bool) {
        p5.push();

        if (in_bool) {
            p5.stroke(255);
        }

        // if (in_bool) {
        if (this.randFill) {
            p5.fill(255);
            // fill(0);
            // fill(color(this.blue_bg[this.sC]));
        } else {
            p5.noFill();
            // fill(255);
            // fill(0);
            // fill(this.cR,this.cG,this.cB);
            // fill(color(this.white_bg[this.sC]));
        }

        // fill(0, 255, 0, 100);
        p5.translate(this.pox, this.poy);
        p5.rotate(this.randRot);
        p5.scale(this.xlSize, this.ylSize);
        // scale(1,2);

        // curveTightness(55);
        p5.beginShape();
        p5.stroke(255);
        // stroke(0);
        p5.strokeWeight(1);

        var k = this.n / this.d;

        for (var a = 0; a < p5.TWO_PI; a += 0.001) {
            // 0.012 , 0.05
            //   if (selAl < 50) {
            //     var r = 100;
            //   }else{
            //     var r = 100 * cos(k * a);
            //   }
            var r;
            if (this.selAl < 50) {
                // 50 = 2 mode // 100 = 1 mode
                r = this.shapeSize;
            } else {
                r = this.shapeSize * p5.cos(k * a);
            }

            var x = r * p5.cos(a * this.aCos);
            var y = r * p5.sin(a * this.aSin);

            // curveVertex(x, y);
            p5.vertex(x, y);
        }
        p5.endShape();

        p5.pop();
    }

    get_x() {
        return this.pox;
    }

    get_y() {
        return this.poy;
    }

    update_size() {
        if (this.xlSize < 0.4) {
            this.xlSize += 0.001;
            this.ylSize += 0.001;
        }
    }
}

export default myShapeArr;
