import React from 'react';

const Copyright = () => {
    return (
        <>
            {/*<span className="fixed bottom-4 right-4 font-normal text-white text-xs md:text-sm xl:text-xl">*/}
            <span className="bottom-4 right-4 font-normal text-white text-[0.5rem] md:text-[0.7rem] xl:text-[0.8rem]">
                {/*<span className="text-2xl font-normal text-white">*/}
                ©2021. developed by NOF
            </span>
        </>
    );
};

export default Copyright;