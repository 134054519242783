import React from "react";
import P5Project from "./component/P5Project";
import Copyright from "./component/Copyright";
import Button from "./component/Button";
import P5State from "./context/P5State";
import Frequency from "./component/Frequency";

import {useCallback, useRef} from 'react';
import {toJpeg} from "html-to-image";


function App() {


    const ref = useRef(null);

    const onButtonClick = useCallback(() => {
        if (ref.current === null) {
            return;
        }

        toJpeg(ref.current, {cacheBust: true,})
            .then((dataUrl) => {
                const link = document.createElement('a');
                link.download = 'AS-STUDIO.jpg';
                link.href = dataUrl;
                link.click();
            })
            .catch((err) => {
                console.log(err);
            });
    }, [ref]);


    return (
        <P5State>
            <div ref={ref}>

                <div className="grid grid-cols-1">
                    <div className="">

                    </div>
                    <div className="z-10">
                        <P5Project/>
                    </div>
                    <div className="justify-self-center z-30">
                        <Button getImage={onButtonClick}/>
                    </div>
                    <div className="frequency  fixed z-40 ">
                        <Frequency/>
                    </div>
                    <div className="text-right relative -top-3 right-3">
                        <Copyright/>
                    </div>
                </div>
            </div>
        </P5State>

    );

}

export default App;
