import React, {useContext} from 'react';

import {BsHexagon, BsHexagonFill} from "react-icons/bs";

import {BiBorderInner, BiCategoryAlt} from "react-icons/bi";

import {FiRotateCw} from "react-icons/fi";

import {AiOutlineDownload} from "react-icons/ai";
import p5Context from "../context/p5-context";

// TODO Solid/Line (Hide When Multiple) | Single/Multiple () | Refresh | Capture

const Button = ({getImage}) => {
    const {toggleFill, isSingle, setToggleFill, setIsSingle, setResetP5} = useContext(p5Context);
    // const [fill, setFill] = useState(true);
    // const [isSingle, setIsSingle] = useState(true);

    return (
        <>
            {isSingle ?
                <button onClick={() => setToggleFill(!toggleFill)}
                        className="m-2"
                >
                    <div className="hidden lg:block">
                        {toggleFill ?
                            <BsHexagonFill color="white" size="1.5rem"/> :
                            <BsHexagon color="white" size="1.5rem"/>
                        }
                    </div>
                    <div className="block lg:hidden">
                        {toggleFill ?
                            <BsHexagonFill color="white" size="1rem"/> :
                            <BsHexagon color="white" size="1rem"/>
                        }
                    </div>
                </button> :
                <button className="m-2 pointer-events-none cursor-none">
                    <div className="hidden lg:block">
                        {toggleFill ?
                            <BsHexagonFill color="dimgrey" size="1.5rem"/> :
                            <BsHexagon color="dimgrey" size="1.5rem"/>
                        }
                    </div>
                    <div className="block lg:hidden">
                        {toggleFill ?
                            <BsHexagonFill color="dimgrey" size="1rem"/> :
                            <BsHexagon color="dimgrey" size="1rem"/>
                        }
                    </div>
                </button>
            }
            <button onClick={() => setIsSingle(!isSingle)}
                    className="m-2"
            >
                <div className="hidden lg:block">
                    {isSingle ?
                        <BiBorderInner color="white" size="1.5rem"/> :
                        <BiCategoryAlt color="white" size="1.5rem"/>
                    }
                </div>
                <div className="block lg:hidden">
                    {isSingle ?
                        <BiBorderInner color="white" size="1rem"/> :
                        <BiCategoryAlt color="white" size="1rem"/>
                    }
                </div>
            </button>
            <button
                className="m-2"
                onMouseUp={() => setResetP5(false)}
                onMouseDown={() => setResetP5(true)}
            >
                <div className="hidden lg:block">
                    <FiRotateCw color="white" size="1.5rem"/>
                </div>
                <div className="block lg:hidden">
                    <FiRotateCw color="white" size="1rem"/>
                </div>
            </button>
            <button
                className="m-2"
                onClick={getImage}
            >
                <div className="hidden lg:block">
                    <AiOutlineDownload color="white" size="1.5rem"/>
                </div>
                <div className="block lg:hidden">
                    <AiOutlineDownload color="white" size="1rem"/>
                </div>
            </button>
        </>
    );
};

export default Button;