import React, {useContext} from 'react';
import myShape from "../myShape";
import myShapeArr from "../myShapeArr";
import Sketch from 'react-p5';
import 'p5/lib/addons/p5.sound';
import p5Context from "../context/p5-context";


let mic, fft;
let inFreq;
let amplitude;
// let my_shape = [];
let my_shapeB;

let my_shapeArr = [];
let com_v = 0;
let t_num = 0;

// eslint-disable-next-line no-unused-vars
let t_count_amp = 0;
let t_count_off_amp = 0;
// eslint-disable-next-line no-unused-vars
let t_size = 0;

const p5jsFunc = {};

let buttonC = false;

// eslint-disable-next-line no-unused-vars
let str_bg = "Fill BG";
let bg_select = 1;
p5jsFunc.show = true;


const P5Project = () => {
    const {toggleFill, isSingle, resetP5, setFrequencyP5} = useContext(p5Context)
    // const [show, setShow] = useState.JSXElement(<GiMultipleTargets />)
    // const [show, setShow] = useState(<GiMultipleTargets />)


    const setup = (p5, canvasParentRef) => {

        const wWidth = window.innerWidth;
        const wHeight = window.innerHeight;
        // p5.createCanvas(540, 540).parent(canvasParentRef);
        let selectSize;
        if (wWidth >= wHeight) {
            selectSize = wHeight * 0.9;
        } else {
            selectSize = wWidth ;
        }

        p5.createCanvas(selectSize , selectSize ).parent(canvasParentRef);

        p5.getAudioContext().suspend();

        my_shapeB = new myShape(p5, p5.width / 2, p5.height / 2);
        // ถ้าไม่ใส่ constructor จะ error
        mic = new p5.constructor.AudioIn();
        mic.start();
        fft = new p5.constructor.FFT();
        mic.connect(fft);
        // p5.getAudioContext().resume();
        amplitude = new p5.constructor.Amplitude();
        amplitude.setInput(mic);

        p5jsFunc.checkCount = 0;

        my_shapeArr.push(new myShapeArr(p5, p5.random(p5.width), p5.random(p5.height)));
    };

    const draw = p5 => {

        p5.background(0);

        if (buttonC || resetP5) {
            if (p5jsFunc.checkCount === 0) {
                my_shapeB = new myShape(p5, p5.width / 2, p5.height / 2);
                my_shapeArr = [];
                my_shapeArr.push(new myShapeArr(p5, p5.random(p5.width), p5.random(p5.height)));
            }
            p5jsFunc.checkCount += 1;
        } else {
            p5jsFunc.checkCount = 0;
        }

        // eslint-disable-next-line no-unused-vars
        fft.analyze();
        let centerFrequency = fft.getCentroid();
        let amplitude_level = amplitude.getLevel();

        inFreq = p5.map(centerFrequency, 20, 14000, -100, 15000);

        if (!isSingle) {
            if (com_v !== t_num) {
                my_shapeArr.push(new myShapeArr(p5, p5.random(p5.width), p5.random(p5.height)));
            }

            com_v = t_num;

            if (amplitude_level > 0.01) {
                t_count_amp += 1;
                t_count_off_amp = 0;
                t_size += 1;
                my_shapeArr[my_shapeArr.length - 1].update(p5, inFreq);
                my_shapeArr[my_shapeArr.length - 1].update_size();
            } else {
                t_count_off_amp += 1;

                if (t_count_off_amp === 30) {
                    t_num += 1;
                }
            }

            ////

            for (let index = 0; index < my_shapeArr.length; index++) {
                if (bg_select === 1 ) {
                    my_shapeArr[index].show(p5, true);
                    // my_shapeB.show(p5, true);
                } else {
                    // my_shapeB.show(p5, false);
                    my_shapeArr[index].show(p5, false);
                }
            }

        }

        if (isSingle) {
            p5.push();
            my_shapeB.update(p5, inFreq);
            if (toggleFill) {
                my_shapeB.show(p5, true);
            } else {
                my_shapeB.show(p5, false);
            }
            p5.pop();
        }

        p5.push();
        p5.stroke(255);
        p5.strokeWeight(0.5);
        let lineSpace = 2;

        let minPosition = p5.width * 0.1;
        let maxPosition = p5.width - (p5.width * 0.1);

        let count = 0;
        for (let i = minPosition; i < maxPosition; i++) {
            if (count % 4 === 0) {
                p5.line(i, p5.height / 2, i + lineSpace, p5.height / 2);
                p5.line(p5.height / 2, i, p5.height / 2, i + lineSpace);

            }
            count++;
        }


        p5.pop();

        // p5.push();
        // p5.fill(255);
        // p5.textSize(0.0296296 * p5.width);
        // p5.text(p5.round(centerFrequency) + " Hz", 0.85 * p5.width, 0.98 * p5.width);
        // p5.pop();


        setFrequencyP5(centerFrequency.toFixed(0))
    };

    p5jsFunc.resetCanvas = () => {
        // my_shapeB = new myShape(p5, p5.width / 2, p5.height / 2);
        console.log('ff');
        console.log();
    };


    p5jsFunc.setTrue = () => {
        buttonC = true;
    };
    p5jsFunc.setFalse = () => {
        buttonC = false;
    };
    p5jsFunc.setBGFill = () => {
        bg_select = 1;
        str_bg = "Fill BG";
    };
    p5jsFunc.setBGNoFill = () => {
        bg_select = 0;
        str_bg = "No BG";
    };
    p5jsFunc.setShow = () => {
        p5jsFunc.show = !p5jsFunc.show;
    };
    p5jsFunc.setShowSingle = () => {
        p5jsFunc.show = true;
    };
    p5jsFunc.setShowMulti = () => {
        p5jsFunc.show = false;
    };

    const touchStarted = p5 => {

        // console.log(55);
        // let state = p5.getAudioContext().state;
        // if (state !== 'running') {

        // p5.getAudioContext().resume();
        // p5.userStartAudio();
        // }
        p5.userStartAudio();
        // console.log(state);
        // p5.getAudioContext().resume();
    };

    const mousePressed = p5 => {
        // console.log("mousePressed");
        p5.userStartAudio();

    };

    return (
        <div className="">
            <Sketch setup={setup} draw={draw} touchStarted={touchStarted} mousePressed={mousePressed}/>
        </div>
    );
};

export default P5Project;